/////////////////
// Header
/////////////////
.header {
  height: 4.125rem;
  z-index: 1;
  position: relative;
  padding: 0rem;
  background: #e5f1fd !important;
  // background:var(--headerbg);
  z-index: 4;
  padding-left: 0rem !important;
  //padding-right:100px;
  padding-top: 0rem;
  transition: all 0.2s ease;

  .header-content {
    height: 100%;
    padding-left: 4rem;
    padding-right: 1.075rem;
    align-items: center;
    display: flex;
    position: relative;
    .department-name {
      color: $primary;
      font-weight: 700;
      font-size: 24px;
      font-family: sans-serif;
    }

    @include respond("phone-land") {
      padding-left: 3.75rem;
      padding-right: 0.938rem;
      &:after {
        left: -5rem;
        width: calc(100% + 5rem);
      }
    }
  }
  /* &.is-fixed{
		background-color: #36246514;
		backdrop-filter: blur(6px);
	} */
  .navbar {
    padding: 0;
    height: 100%;
    width: 100%;
    .navbar-collapse {
      height: 100%;
      width: 100%;
    }
  }
  @include respond("phone-land") {
    padding-top: 0;
  }
  @include custommq($max: 30.9375rem) {
    .navbar {
      .navbar-collapse .department-name {
        display: none;
      }
    }
  }
  .header-bar {
    height: 1.1rem;
    background-color: $primary;
  }
}

////////////////////////
// CSS Pulse Effect
////////////////////////
@mixin circle($circleSize) {
  width: $circleSize;
  height: $circleSize;
  border-radius: $circleSize/2;
}

@for $i from 2 through 15 {
  [data-headerbg="color_#{$i}"] .header {
    background: var(--headerbg);
  }
}

/* pulse in SVG */

svg.pulse-svg {
  overflow: visible;
  .first-circle {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation: pulse-me 3s linear infinite;
    animation: pulse-me 3s linear infinite;
    fill: var(--primary);
  }
  .second-circle {
    @extend .first-circle;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }
  .third-circle {
    @extend .first-circle;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
  }
}

/* pulse in CSS */

.pulse-css {
  @include circle(1rem);
  border-radius: 3.5rem;
  height: 1.25rem;
  position: absolute;
  background: #fe8630;
  right: 0.375rem;
  top: 0.3125rem;
  border: 0.25rem solid $white;
  width: 1.25rem;
  &:after,
  &:before {
    content: "";
    @include circle(1rem);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: -0.2rem;
    background-color: #d8b9c3;
    margin: auto;
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation: pulse-me 3s linear infinite;
    animation: pulse-me 3s linear infinite;
    @at-root [direction="rtl"] & {
      left: auto;
      right: -0.2rem;
    }
  }
  @include respond("laptop") {
    height: 1rem;
    width: 1rem;
  }
}

@-webkit-keyframes pulse-me {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    opacity: 0;
  }
  50% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.09;
  }
  100% {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0;
  }
}

@keyframes pulse-me {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    opacity: 0;
  }
  50% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.09;
  }
  100% {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0;
  }
}

// [data-sidebar-style="full"],
// [data-sidebar-style="overlay"] {
//     .header {
//         width: 100%;
//         @include respond ('laptop') {
//             width: 100%;
//             padding-left: 17rem;
//         }
//         @include custommq($max: 63.9375rem) {
//             width: 100%;
//             padding-left: 5rem;
//         }
//     }
// }

[data-sidebar-style="mini"] {
    .header {
      width: calc(100% - 3.75rem);
      left: 3.75rem;
		//height:5rem;
		padding-left: 7.5rem;
		@include custommq($max: 63.9375rem){
			//height:5rem;
			padding-left: 6.5rem;
		}
    }
}

[data-sidebar-style="compact"] {
    .header {
        width: 100%;
        padding-left: 12.5rem;
		.header-content{
			padding-left: 2.5rem;
		}
    }
}

[data-header-position="fixed"] {
  .header {
    position: fixed;
    top: 0;
  }
  .deznav {
    margin-top: 0;
  }
}
[data-sidebar-style="compact"][data-header-position="fixed"][data-container="boxed"][data-layout="vertical"] {
  .header {
    width: 74.9375rem;
  }
}
[data-sidebar-style="modern"] {
    .header {
		width: 100%;
		padding-left: 11.9rem;
    

    }
	&[data-layout="horizontal"] {
		.nav-header .brand-logo{
		    justify-content: start;
		}
		.header .header-content{
            padding-left: 1.875rem;
			
        }
	}
}