


.footer {
    padding-left:6rem;
    font-size: 0.813rem;
	font-weight:400;
    .copyright {
        padding: 0.9375rem;
        p {
            text-align: center;
            margin: 0;
			color:#918f8f;
        }
        a{
            color: var(--primary)! important;
        }
    }
}



