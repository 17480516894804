.c-pointer {
  cursor: pointer;
}
.react-select {
  .select__control {
    &.select__control--is-focused {
      border-color: $primary;
      box-shadow: none;
      border-width: 1px;
    }
  }
  &.is-invalid {
    .select__control {
      border-color: $danger !important;
    }
  }
}
